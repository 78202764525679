import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const ApplicationModuleColumn = [
    {
        name: <IntlMessages
            id="iam.applicationModules.datatable.column.code"
        />,
        selector: 'code',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.applicationModules.datatable.column.name"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.applicationModules.datatable.column.type"
        />,
        selector: 'applicationType',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.applicationModules.datatable.column.businessDomain.name"
        />,
        selector: 'businessDomain.name',
        sortable: false,
        filtrable: true,

    },
    {
        name: <IntlMessages
            id="iam.applicationModules.datatable.column.organization"
        />,
        selector: 'organizationCode',
        sortable: false,
        filtrable: true,

    },

];