import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const BusinessDomainColumn = [
    {
        name: <IntlMessages
            id="iam.businessDomains.datatable.column.code"
        />,
        selector: 'code',
        sortable: false,
        filtrable: true,
        className: "text-center",
        headerClassName: "react-table-header-class"
    },
    {
        name: <IntlMessages
            id="iam.businessDomains.datatable.column.name"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
        className: "text-center",
        headerClassName: "react-table-header-class"
    },
    {
        name: <IntlMessages
            id="iam.businessDomains.datatable.column.organization"
        />,
        selector: 'organizationCode',
        sortable: false,
        filtrable: true,
        className: "text-center",
        headerClassName: "react-table-header-class"
    },
];