import React from "react";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";

//export const HOST_ENV = `${process.env.REACT_APP_API_URL}/nomenclature-service`;

export const getAllMethod = (host, context, result, error) => {
    axios.get(host + "/" + context + "/", getConfigObject()).then(res => {
        result(res);
    }).then(error => {
            if (error) error()
        }
    );
};

export const getAllUserWithPagination = (host, context, result, size,page) => {
    axios.get(host + "/" + context + `?size=${size}&page=${page}`, getConfigObject()).then(res => {
        result(res);
    }).then(error => {
            if (error) error()
        }
    );
};


export const search = (host, context, callbackRes, callbackError, resource, size, page ) => {
    
    axios.put(host+"/"+ context+ `/advanced_search/get?size=${size}&page=${page}&sort=lastName,ASC`, resource, getConfigObject()).then(res => {
        callbackRes(res);
    }).catch(function (error) {
        callbackError(error);
    });
};



function updateSelect(resourceName, list, dataSelect){
    let dataSelectTemp = dataSelect;
    dataSelectTemp[resourceName] = list;
    return dataSelectTemp;
}


export const getAllForSelect = (host, context, resulat, getLabel) => {
    let listForSelect = [];

    axios.get(host + "/" + context, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item => listForSelect.push({
                key: item.id,
                label: getLabel? getLabel(item) : item.name,
                value: item.id,
                object:item
            }));
        }

        resulat(listForSelect);

    }).then(error => {
            //if (error) error()
        }
    );
};


export const getByIdMethod = (host, context, result, dep, error, id) => {
    if (id !== undefined)
        axios.get(host + "/" + context + "/" + id, getConfigObject()).then(res => {
            result(res.data);
            dep(res.data)
        });
};

export const editMethod = (host, context, callbackRes, callbackError, resource) => {

    if (resource.id !== undefined && resource.id !== "new") {
        axios.put(host + "/" + context + "/" + resource.id, resource, getConfigObject()).then(res => {
            callbackRes();
        }).catch(function (error) {
            callbackError(error.response.data.message);
        });
    } else {
        axios.post(host + "/" + context, resource, getConfigObject()).then(res => {
            callbackRes();
        }).catch(function (error) {
            callbackError(error.response.data.message);
        })
    }
};


export const deleteMethod = (host, context, callbackDelete, resources) => {
    resources.forEach(element =>
        axios
            .delete(`${host}/${context}/${element.id}`, getConfigObject())
            .then(res => {
                callbackDelete();
            })
    );
};