import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import useForm from "react-hook-form";
import {AdaButton, getOrganizationSelected} from "@adaming/ada-react-component";
import {CustomInput} from "reactstrap";

import {AdaInputText} from "@adaming/ada-react-component";
import {connect} from "react-redux";

import filtersAction from "../../../../redux/filters/actions";
import IntlMessages from "../../../../helpers/intlMessages";
import { GetOrganizationIdFromSession } from "../../../components/services/session.service";

const {updateFilters} = filtersAction;

const AdvancedSearch = (props) =>  {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const handlerAdvancedSearch = props.handlerAdvancedSearch;
    const handlerCancelAdvancedSearch = props.handlerCancelAdvancedSearch;

 

    const dispatch = (data) =>{
        props.updateFilters({...props.filters.filters, ...data})
    }

    const onSubmit = (data) => {
        handlerAdvancedSearch({...props.filters.filters});
    } 

    const clear = () => {
        reset({
            "userName": "",
            "firstName": "",
            "lastName": null,
            "code": "",
            "email":"",
            "expired" :null,
            "enable": true,
            "disable": false,
            "byEnable": true
        });
        props.updateFilters({
            "userName": "",
            "firstName": "",
            "lastName": null,
            "code": "",
            "email":"",
            "expired" :null,
            "enable": true,
            "disable": false,
            "byEnable": true

        })
        handlerCancelAdvancedSearch();
    }


    return (<div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Row className="col-sm-12 pt-3">
                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id={`iam.user.firstName`} />

                                    <AdaInputText 
                                        name="firstName" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, firstName: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.firstName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id={`iam.user.lastName`}/>
                                    <AdaInputText
                                        name="lastName" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, lastName: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.lastName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        
                    </Col>


                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id={`iam.user.userName`}/>

                                    <AdaInputText
                                        name="userName" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, userName: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.userName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id={`iam.user.email`}/>
                                    <AdaInputText
                                        name="email" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, email: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.email}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>


                    <Col className="col-sm-3">
                     
                        <Row>
                            <Col>
                                <FormGroup>
                                    <CustomInput
                                    id="enable"
                                    type="checkbox"
                                    name="enable"
                                    defaultChecked={props.filters.filters.enable != undefined ? props.filters.filters.enable: false }
                                    innerRef={register}
                                    onChange={(e)=> {props.updateFilters({...props.filters.filters, enable: e.target.checked })}}
                                    label={
                                        <IntlMessages  id={`iam.user.datatable.column.enabled`} />
                                    }
                                />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <CustomInput
                                    id="disable"
                                    type="checkbox"
                                    name="disable"
                                    defaultChecked={props.filters.filters.disable != undefined ? props.filters.filters.disable: false }
                                    innerRef={register}
                                    onChange={(e)=> {props.updateFilters({...props.filters.filters, disable: e.target.checked })}}
                                    label={
                                        <IntlMessages  id={`iam.user.datatable.column.disabled`} />
                                    }
                                />
                            </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <CustomInput
                                    id="expired"
                                    type="checkbox"
                                    name="expired"
                                    defaultChecked={props.filters.filters.expired != undefined ? props.filters.filters.expired: false }
                                    innerRef={register}
                                    onChange={(e)=> {props.updateFilters({...props.filters.filters, expired: e.target.checked })}}
                                    label={
                                        <IntlMessages id={`iam.user.datatable.column.expired`} />
                                    }
                                />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row className="pt-4">
                            <Col>
                                <FormGroup>
                                    <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={() => clear()}>
                                        <IntlMessages id={`iam.common.cancel`}/>
                                    </AdaButton>
                                    <AdaButton
                                        className="c-warning col-xl-6"
                                        type="submit"
                                        style={{marginLeft: 10, height: 35}}
                                    >
                                        <IntlMessages id={`iam.common.search`}/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </form>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFilters
    }
)(AdvancedSearch);
