import React, {Fragment} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import useForm from "react-hook-form";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import Input from "reactstrap/es/Input";
import {editMethod, getAllForSelect, getByIdMethod} from "../../components/services/crud.service";

const context = "users";
export default function UserForm(props, obj) {
    const id = props.location.pathname.split("/")[2];

    const {register, handleSubmit, errors, setValue} = useForm();
    const [user, setUser] = React.useState({});
    const [roles, setRoles] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [checkAdmin, setCheckAdmin] = React.useState({isCheck: false});
    const [selectedRoles, setSelectedRoles] = React.useState();

    let history = useHistory();

    React.useEffect(() => {
        register({name: "username"}, {required: "Ce champ est obligatoire."});
        register({name: "lastName"}, {required: "Ce champ est obligatoire."});
        register({name: "firstName"}, {required: "Ce champ est obligatoire."});
        register({name: "roleLegacy"});
        register(
            {name: "email"},
            {
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse email invalid"
                }
            }
        );
        register({name: "roles"}, {required: "Ce champ est obligatoire."});
    }, []);

    React.useEffect(() => {
        if (id !== undefined) {
            getByIdMethod(props.host, props.context, setUser, init, setErrorMessage, id)
        }
    }, [id]);

    
    const getLabel = item => {
        return item.name+"-"+item.organizationCode;
    }

    React.useEffect(() => {
        getAllForSelect(props.host, 'roles', setRoles, getLabel);
    }, []);

    function init(data) {
        let roles = undefined;
        if (data.roles) {
            roles = [];
            data.roles.forEach(item => {
                    roles.push({
                        key: item.id,
                        label: item.name+"-"+item.organizationCode,
                        value: item.id,
                        object: item
                    })
                }
            );
        }
        setValue("roles", data.roles);
        setSelectedRoles(roles);
    };

    const handleMultiChange = selectedOption => {
        let roles = undefined;
        if (selectedOption) {
            roles = [];
            selectedOption.map(item => roles.push(item.object));
        }
        setValue("roles", roles);
        setSelectedRoles(selectedOption);
    };

    const handleChangeCheckBox = () => {
        setCheckAdmin({isCheck: !checkAdmin.isCheck})
        console.log("handleChangeCheckBox checkAdmin : ", checkAdmin.isCheck);
    };

    const onSubmit = data => {

        if (id !== undefined && id !== "new") {
            data = {...data, "id": id};
        }
        console.log(data);
        editMethod(props.host, context, () => history.push("/" + props.context), setErrorMessage, data)
    };

    return (
        <Fragment>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.username"/>
                                                <AdaInputText
                                                    name="username"
                                                    defaultValue={user.username}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.lastName"/>
                                                <AdaInputText
                                                    name="lastName"
                                                    defaultValue={user.lastName}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.firstName"/>
                                                <AdaInputText
                                                    name="firstName"
                                                    defaultValue={user.firstName}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.email"/>
                                                <AdaInputText
                                                    name="email"
                                                    defaultValue={user.email}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.roles"/>
                                                <AdaSelect
                                                    name="roles"
                                                    isMulti
                                                    value={selectedRoles}
                                                    options={roles}
                                                    onChange={handleMultiChange}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.roles.legacy"/>
                                                <AdaInputText
                                                    name="roleLegacy"
                                                    defaultValue={user.roleLegacy}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages id="iam.user.administrator"/>
                                                <Input
                                                    type="checkbox"
                                                    defaultChecked={user.administrator}
                                                    placeholder="Administrator"
                                                    name="Administrator"
                                                    onClick={handleChangeCheckBox}
                                                    ref={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <AdaButton type="submit">
                                                    <IntlMessages id="iam.common.submit"/>
                                                </AdaButton>
                                                <AdaButton
                                                    style={{marginLeft: 10}}
                                                    onClick={() => history.push(`/${context}`)}
                                                >
                                                    <IntlMessages id="iam.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
