import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const PermissionColumn = [
    {
        name: <IntlMessages
            id="iam.permission.datatable.column.code"
        />,
        selector: 'code',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.permission.datatable.column.name"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
    },
    // {
    //     name: <IntlMessages
    //         id="iam.permission.datatable.column.listActions"
    //     />,
    //     selector: 'actionPermissions',
    //     sortable: false,
    //     filtrable: true,
    // },
    {
        name: <IntlMessages
            id="iam.permission.datatable.column.application.module"
        />,
        selector: 'applicationModule.name',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.permission.datatable.column.organization"
        />,
        selector: 'applicationModule.organizationCode',
        sortable: false,
        filtrable: true,
    },

];