
import { GetOrganizationIdFromSession } from '../../views/components/services/session.service'
import filtersAction from './actions'

const initState = {
    filters: {
            "userName": "",
            "firstName": "",
            "lastName": null,
            "code": "",
            "email":"",
            "expired" :null,
            "enable": true,
            "disable": false,
            "byEnable": true
    
    }
}


export default function rootReducer(state = initState, action) {

    switch (action.type) {
        case filtersAction.UPDATE_FILTERS:
            return {
                ...state,
                filters: action.filters,
            }
        default:
            return state
    }
}